
import { createVNode, defineComponent } from "vue";
import { Modal } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { message } from "ant-design-vue";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import moment from "moment";
import "moment/dist/locale/zh-cn";

moment.locale("zn");
const columns = [
  {
    title: "账号", //名称的
    dataIndex: "userName", //与下面data里面的数据统一起来，显示的
    key: "userName", //是关键词，建议个dataindex统一起来，key在dataindex之后的话可以不写
    // slots: { customRender: "name" },//指定渲染的模板，可以指定列，进行单独的渲染
    width: 250,
    align: "center",
  },
  {
    title: "电话",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
    width: 200,
    align: "center",
  },
  {
    title: "职责",
    dataIndex: "role",
    key: "role",
    slots: { customRender: "myduty" }, //customRender是固定写法，这个myduty是自己的模板
    align: "center",
    width: 200,
  },
  {
    title: "操作",
    slots: { customRender: "action" },
    key: "action",
    align: "center",
  },
];

const data: any[] = [];

export default defineComponent({
  name: "Personalmanage",
  data() {
    return {
      enUS: enUS,
      zhCN: zhCN,
      // 区分海漂或者是海滩
      regionId0: '1',
      regionId1: '1',
      value: "",
      data: data,
      columns: columns,
      addvisible: false,
      editvisible: false,
      distributevisible: false,
      distrmessage: "",
      confirmLoading: false,
      confirmLoading2: false,
      confirmLoading3: false,
      createValue:[],
      inputData: {
        account: "",
        password: "",
        phone: "",
        duty: undefined,
      },
      editData: {
        account: "",
        password: "",
        phone: "",
        duty: undefined,
        employeeId: "",
      },
      task: {
        name: "",
        region: undefined,
        message: "",
        startTime: "",
        endTime: "",
        senderId: "",
        recipientId: "",
        success: 0,
        sedimentation_type: '0',
      },
      pagination: {
        pageSize: 9, //一页是8条数据
        total: 10,
        current: 1,
      },
      // page: 1, //默认第一页
      loading: false,
      timer: 0, //计时器
    };
  },
  methods: {
    // 权限控制
    // 控制编辑按钮的权限
    editControl(text: any) {
        if(text.role == 0 && text.userId != this.$storage.get("userinfo").data.userId)
          return true;
        else return false;
    },
    // 控制删除按钮的权限
    deleteControl(text: any) {
        if(text.role == 0 && text.userId != this.$storage.get("userinfo").data.userId)
          return true;
        else return false;
    },

    // 搜索请求用户信息    
    // 真正的查询用户请求
    getPersonData() {
      this.loading = true;
      let api = "http://39.100.158.75:8080/manager/findEmployee";
      this.$axios({
        url: api,
        method: "GET",
        headers: {
          token: this.$storage.get("userinfo").data.token,
        },
        params: {
          page: this.pagination.current,
          limit: this.pagination.pageSize,
          keyWord: this.value,
        },
      })
        .then((response: any) => {
          // console.log(response);
          // console.log(response.data.data.list);
          if (response.data.data == null) {
            message.info(response.data.message);
            this.data = [];
            this.pagination.total = 0;
            this.loading = false;
          } else {
            this.data = response.data.data.list;
            this.pagination.total = response.data.data.totalPageCount;
            this.loading = false;
          }
        })
        .catch((error: any) => {
          console.log(error);
        });
    },
    // 分页查询用户
    getUserData(event: any) {
      this.pagination.current = event.current;
      this.getPersonData();
    },
    // 搜索用户-输入完成后的自动搜索
    getSearchData() {
      if (this.value != "") {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.pagination.current = 1
          this.getPersonData();
        }, 600);
      }
    },
    // 搜索用户-输入完成后的手动点击搜索按钮搜索
    onSearch() {
      this.pagination.current = 1
      this.getPersonData();
    },
    
    // 分配任务
    // 分配任务的按钮 这个函数控制的是点击后弹框的文字控制以及点击后弹框的出现
    Distribution(data: any) {
      console.log(data);
      this.distrmessage = "分配任务给----" + data.userName;
      this.task.senderId = this.$storage.get("userinfo").data.userId;
      this.task.recipientId = data.userId;
      this.distributevisible = true;
    },
    // 分配任务日期的确认按钮
    onChange(value: any, dateString: any) {
      this.createValue = value;
      this.task.startTime = dateString[0];
      this.task.endTime = dateString[1];
    },
    onOk() {},
    // 分配任务弹框中的确认按钮 真正的分配任务的请求
    distribute() {
      if (
        this.$storage.get("userinfo").data.token == null ||
        this.$storage.get("userinfo").data.token == ""
      ) {
        (this as any).$router.push({
          path: "/login",
        });
      } else {
        if (this.task.name == "" || this.task.name == null) {
          message.error("请输入任务名称");
        } else if (this.task.message == "" || this.task.message == null) {
          message.error("请输入任务描述");
        } else if (this.task.startTime == "" || this.task.endTime == "") {
          message.error("请选择时间");
        } 
        // else if (this.task.region == undefined) {
        //   message.error("请选择区域");
        // } 
        else {
          // console.log(this.editData);
          let regionId = '';
          if(this.task.sedimentation_type === '1') {
            regionId = this.regionId1;
          } else if(this.task.sedimentation_type === '2') {
            regionId = this.regionId0
          } else {
            regionId = '0'
          }
          this.confirmLoading3 = true;
          let api = "http://39.100.158.75:8080/mission/insertOneMission";
          this.$axios({
            url: api,
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              token: this.$storage.get("userinfo").data.token,
            },
            params: {
              senderId: this.task.senderId,
              recipientId: this.task.recipientId,
              startTime: this.task.startTime,
              endTime: this.task.endTime,
              regionId: regionId,
              message: this.task.message,
              success: this.task.success,
              name: this.task.name,
              sedimentation_type: this.task.sedimentation_type,
            },
          })
            .then((response: any) => {
              if (response.data.success) {
                message.success("分配任务成功");
                this.task.name = "";
                this.task.region = undefined;
                this.task.startTime = "";
                this.task.endTime = "";
                this.task.message = "";
                this.createValue = [];
                this.task.sedimentation_type = '0';
                this.regionId0 = '1';
                this.regionId1 = '1';
              } else {
                message.error(response.data.message);
              }
            })
            .catch((error: any) => {
              console.log(error);
            });
          setTimeout(() => {
            this.distributevisible = false;
            this.confirmLoading3 = false;
          }, 2000);
        }
      }
    },
    // 关闭清空分配任务弹框
    handleCancel3() {
      this.task.name = "";
      this.task.region = undefined;
      this.task.startTime = "";
      this.task.endTime = "";
      this.task.message = "";
      this.createValue = [];
      this.task.sedimentation_type = '0';
      this.regionId0 = '1';
      this.regionId1 = '1';
    },

    //编辑用户
    // 获取编辑的用户信息 打开修改用户的弹框
    doEdit(data: any) {
      this.editvisible = true;
      console.log(data);
      this.editData.account = data.userName;
      this.editData.password = data.password;
      this.editData.phone = data.phoneNumber;
      this.editData.duty = undefined;
      this.editData.employeeId = data.userId;
    },
    // 真正的编辑用户请求
    editDoAddKeywords() {
      if (
        this.$storage.get("userinfo").data.token == null ||
        this.$storage.get("userinfo").data.token == ""
      ) {
        (this as any).$router.push({
          path: "/login",
        });
      } else {
        if (this.editData.account == "" || this.editData.account == null) {
          message.error("请输入账号");
        } else if (
          this.editData.password == "" ||
          this.editData.password == null
        ) {
          message.error("请输入密码");
        } else if (this.editData.phone == "" || this.editData.phone == null) {
          message.error("请输入电话号码");
        } else if (this.editData.duty == undefined) {
          message.error("请选择职能");
        } else {
          // console.log(this.editData);

          this.confirmLoading2 = true;
          let api = "http://39.100.158.75:8080/manager/updateOneEmployee";
          this.$axios({
            url: api,
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              token: this.$storage.get("userinfo").data.token,
            },
            params: {
              name: this.editData.account,
              phoneNumber: this.editData.phone,
              password: this.editData.password,
              role: this.editData.duty,
              employeeId: this.editData.employeeId,
            },
          })
            .then((response: any) => {
              // console.log(this.$storage.get("userinfo"));
              // console.log(this.$storage.get("userinfo").data.token);
              // console.log(this.inputData);
              // console.log(response);
              if (response.data.success) {
                message.success(response.data.message);
                this.getPersonData();
              } else {
                message.error(response.data.message);
              }

              // console.log(response);
            })
            .catch((error: any) => {
              console.log(error);
            });
          setTimeout(() => {
            this.editvisible = false;
            this.confirmLoading2 = false;
          }, 2000);
        }
      }
    },
    // 真正的删除用户请求
    deleteUser(data: any) {
        let that = this;
      // console.log(data);
      Modal.confirm({
          title: '你确定要删除这个账号吗？',
          icon: createVNode(ExclamationCircleOutlined),
          content: createVNode('div', {style: 'color: red;'}, '本次操作将会删除这个账号！'),
          okText: '确定',
          cancelText: '取消',
          onOk() {
              if (data.userId == that.$storage.get("userinfo").data.userId) {
                message.info("您不可以删除自己的账号!!!");
              } else {
                let api = "http://39.100.158.75:8080/manager/deleteEmployeeByEmployeeId";
                that.$axios({
                    url: api,
                    method: "DELETE",
                    headers: {
                        "Content-Type": "	application/x-www-form-urlencoded",
                        token: that.$storage.get("userinfo").data.token,
                    },
                    params: {
                        employeeId: data.userId,
                    },
                })
                .then((response: any) => {
                    if (response.data.success) {
                        message.success(response.data.message);
                    } else {
                        message.error(response.data.message);
                    }
                    // 这里是判断删除掉某一用户信息时，这一页面是否还有数据，是否还可以继续请求
                    let pagNum = that.pagination.total / that.pagination.pageSize;
                    if((that.pagination.total-1) % that.pagination.pageSize === 0 && (that.pagination.current) > pagNum){                     
                      that.pagination.current =
                      that.pagination.current - 1 > 0 ? that.pagination.current - 1 : 1;
                    }
                    that.getPersonData();
                })
                .catch((error: any) => {
                    console.log(error);
                });
            }
          },
          onCancel() {}
      })
      
    },
    // 关闭清空编辑用户弹框
    handleCancel2() {
      this.editData.account = "";
      this.editData.password = "";
      this.editData.phone = "";
      this.editData.duty = undefined;
    },

    // 添加用户
    // 打开添加用户对话框
    showAddmodel() {
      this.addvisible = true;
    },
    // 添加用户信息获取以及真正的请求
    doAddKeywords() {
      if (
        this.$storage.get("userinfo").data.token == null ||
        this.$storage.get("userinfo").data.token == ""
      ) {
        (this as any).$router.push({
          path: "/login",
        });
      } else {
        if (this.inputData.account == "" || this.inputData.account == null) {
          message.error("请输入账号");
        } else if (
          this.inputData.password == "" ||
          this.inputData.password == null
        ) {
          message.error("请输入密码");
        } else if (this.inputData.phone == "" || this.inputData.phone == null) {
          message.error("请输入电话号码");
        } else if (this.inputData.duty == undefined) {
          message.error("请选择职能");
        } else {
          this.confirmLoading = true;
          let api = "http://39.100.158.75:8080/user/register";
          this.$axios({
            url: api,
            method: "post",
            headers: {
              "Content-Type": "application/json",
              token: this.$storage.get("userinfo").data.token,
            },
            data: {
              userName: this.inputData.account,
              password: this.inputData.password,
              role: this.inputData.duty,
              phoneNumber: this.inputData.phone,
            },
          })
            .then((response: any) => {
              // console.log(this.$storage.get("userinfo"));
              // console.log(this.$storage.get("userinfo").data.token);
              // console.log(this.inputData);
              if (response.data.success) {
                message.success(response.data.message);
                this.getPersonData();
              } else {
                message.error(response.data.message);
              }

              // console.log(response);
            })
            .catch((error: any) => {
              console.log(error);
            });
          setTimeout(() => {
            this.addvisible = false;
            this.confirmLoading = false;
            this.inputData.account = "";
            this.inputData.password = "";
            this.inputData.phone = "";
            this.inputData.duty = undefined;
          }, 2000);
        }
      }
    },
    // 关闭清空添加用户弹框
    handleCancel() {
      this.inputData.account = "";
      this.inputData.password = "";
      this.inputData.phone = "";
      this.inputData.duty = undefined;
    },

    // 校验
    // 添加用户和编辑用户信息时的电话格式校验
    inputFilter(e: any) {
      if (
        (e.keyCode !== 8 && e.keyCode < 48) ||
        (e.keyCode > 57 && e.keyCode < 96) ||
        e.keyCode > 105
      ) {
        e.preventDefault(); //取消e的执行
        return false;
      }
    },
  },
  mounted() {
    this.getPersonData();
    message.warning('本页面建议横屏使用！', 4);
  },
});
